<template>
  <router-view></router-view>
</template>
<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { DataService } from '@/config/dataService/dataService';

export default {
  name: 'Users',
  setup() {
    const { state, commit } = useStore();
    const activeStoreView = computed(() => state.auth.activeStoreView);

    onMounted(async () => {
      const {
        data: { data, status },
      } = await DataService.get(
        `${activeStoreView.value.code}/products/getProductFilters/${state.auth.user.client_id}`,
      );

      if (status === 'success') {
        commit('setAttributeOptions', data);
      }
    });
  },
};
</script>
