<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> Sign in </sdHeading>
        <a-form-item name="username" label="Email Address">
          <a-input type="email" v-model:value="formState.email" />
        </a-form-item>
        <a-form-item name="username" label="Company name">
          <a-input type="text" v-model:value="formState.companyName" />
        </a-form-item>
        <a-form-item name="password" initialValue="123456" label="Password">
          <a-input type="password" v-model:value="formState.password" placeholder="Password" />
        </a-form-item>
        <div class="auth-form-action">
          <router-link class="forgot-pass-link" to="/auth/forgot-password"> Forgot password? </router-link>
        </div>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large" :disabled="isLoading">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';

const SignIn = {
  name: 'SignIn',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);

    const handleSubmit = async () => {
      await dispatch('login', formState);
    };

    const formState = reactive({
      email: '',
      companyName: '',
      password: '',
    });

    return {
      isLoading,
      handleSubmit,
      formState,
    };
  },
};

export default SignIn;
</script>
